<template>
  <div>
    <!-- Title -->
    <b-card-title class="text-primary mb-3">Checkout</b-card-title>

    <!-- Alert -->
    <b-alert variant="info" dismissible show fade>
      Enter your password to proceed
    </b-alert>

    <authenticate
      v-if="mobile"
      :mobile="mobile"
      @success="successAuth"
      @forgotClick="forgotClick"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { userForgotPassword } from "../../../../../apis";
import Authenticate from "../../../../../components/auth/Authenticate.vue";

export default {
  components: { Authenticate },
  props: ["store"],
  created() {
    if (!this.mobile) {
      // no mobile no. found in route params
      this.$router.push({ name: "Cart" });
      return;
    }
  },
  data() {
    return {
      routeParams: this.$route.params,
    };
  },
  computed: {
    mobile() {
      return this.routeParams.mobile;
    },
  },
  methods: {
    ...mapActions({
      saveToken: "login/saveToken",
    }),
    successAuth(data) {
      //save token
      this.saveToken(data.token).then(() => {
        // redirect to place order screen
        this.$router.push({ name: "CartPlaceOrder" });
      });
    },

    forgotClick() {
      userForgotPassword(this.mobile).then((data) => {
        // redirect to reset password page
        this.$router.push({
          name: "CartResetPassword",
          params: { otpData: { ...data }, ...this.routeParams },
        });
      });
    },
  },
};
</script>
