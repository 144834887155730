<template>
  <b-form @submit.prevent="onSubmit" class="pt-3">
    <b-form-group
      :invalid-feedback="getErrorMessage(errors, 'mobile')"
      :state="getErrorState(errors, 'mobile')"
    >
      <b-form-input
        :state="getErrorState(errors, 'mobile')"
        id="mobile"
        :value="mobile"
        disabled
      />
    </b-form-group>

    <form-password
      :invalid-feedback="
        getErrorMessage(errors, 'password') ||
          getErrorMessage(errors, 'message')
      "
      :state="
        getErrorState(errors, 'password') || getErrorState(errors, 'message')
      "
      id="password"
      v-model="form.password"
      placeholder="Password"
    ></form-password>

    <!-- Forgot Password Link -->
    <div>
      <a @click="onForgotClick" class="baycho-blue small cursor-pointer">
        Forgot Password?
      </a>
    </div>

    <b-button
      type="submit"
      variant="primary"
      class="text-uppercase text-white mt-5"
      pill
      block
    >
      <span> <b-icon-arrow-right scale="0.7" /> Continue </span>
    </b-button>
  </b-form>
</template>

<script>
import { variables } from "@/debug";

import { authenticate } from "../../apis";

export default {
  props: {
    mobile: { required: true },
  },
  data() {
    return {
      form: {
        password: variables.password,
      },
      errors: null,
    };
  },
  methods: {
    onSubmit() {
      authenticate({ mobile: this.mobile, password: this.form.password })
        .then((data) => {
          this.$emit("success", data);
        })
        .catch(this.onErrors);
    },
    onForgotClick() {
      this.$emit("forgotClick");
    },
    onErrors({ data }) {
      this.errors = data.errors;
    },
  },
};
</script>
